<!-- 游戏头部 -->
<template>
    <van-row justify="space-between"  style=" min-height: 40px;">
        <van-col :span="24">
            <div class="header-wrap">
                <header class="header">
                    <div class="header-main">
                        <div class="header-left" style="height: 34px;">
                            <img src="/img/login/logo_6.png" alt="logo图"> 
                        </div>
                        <div class="header-right">
                            <div style="margin-right: 20px;">
                                <span class="google-material-icons" >
                                    search
                                </span>
                            </div>
                            <div style="margin-right: 20px;">
                                <router-link tag="li" class="nav-list-item"  :to="{    path: '/helper'   }" >
                                    <span class="google-material-icons" >
                                        help_outline
                                    </span>
                                </router-link>
                            </div>
                            <div class="header-user" >
                                <!--  <button   v-if="data.loginState" class="i-button header__login i-button__primary" @click="login" >登录 </button>  -->
                 
                                <!--  <button   v-if="data.loginState" class="i-button header__login i-button__primary" @click="login" >登录 </button>  -->

                                <img   v-if="loginState" src="https://fonts.gstatic.com/s/i/productlogos/avatar_anonymous/v4/web-32dp/logo_avatar_anonymous_color_1x_web_32dp.png" alt=""  @click="login" />
                                <!--  登录成功后点击跳转到用户中心  -->
                                <img    src="https://fonts.gstatic.com/s/i/productlogos/avatar_anonymous/v4/web-32dp/logo_avatar_anonymous_color_1x_web_32dp.png" alt="" @click="skipUserCenter" v-else />  
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </van-col>
      </van-row>
</template>
<style>
    .header-wrap {
       position: fixed;
       z-index: 998;
       top: 0;
       left: 0;
       width: 100%;
       background: rgb(248, 248, 248);
       max-width: 15.45894rem;
       right: 0;
       margin: 0 auto;
   }
   .header {
       width: 100%;
  
       font-size: 16px;
       user-select: none;
       position: relative;
   }
   .header-main {
       display: -webkit-box;
       display: -ms-flexbox;
       display: flex;
       -webkit-box-align: center;
       -ms-flex-align: center;
       align-items: center;
       height: 45px;
       justify-content: space-between;

   }
   .header-left {
       position: relative;
       height: 100%;
       color: #51a7e0;
       font-size: 18px;
       font-weight: 500;
       line-height: 26px;
     /*  align-items: center;*/
       display: flex;
       margin-left: 12px;
       text-align: center;
   }
   .header-main img {
      /* width: 4.3rem;*/
       height: 35px; 
       vertical-align: middle;
   }
   .header-right {
       display: flex;
       position: relative;
       align-items: center;
       height: 100%;
   }
   .header-user {
       height: -webkit-fit-content;
       height: -moz-fit-content;
       height: fit-content;
       -webkit-box-orient: vertical;
       -webkit-box-direction: normal;
       -ms-flex-direction: column;
       flex-direction: column;
      /* padding: 6px 6px 0;*/
       color: #5c5d61;
      /* background-color: #fff;*/
       font-size: 14px;
       cursor: pointer;
       display: flex;
       position: relative;
       margin-right: 10px;
   }
   .google-material-icons {
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            color: rgb(95, 99, 104);
            -webkit-font-smoothing: antialiased;
        }
</style>
<script>
    import { reactive,toRefs,onMounted } from "vue";
    import { useStore } from 'vuex';
    import { useRouter } from "vue-router";
    export default {
        setup(){
            const store = useStore();
            const router = useRouter();
            onMounted(() => {
                //登录判断
                alreadyLogged();
            });
            
            const state = reactive({
                loginState: false
            });

            const login = ()=>{
                store.commit("setNeedLogin", true); 
            }
            const skipUserCenter = ()=>{
                goTo("/my",{});
            }
            //判断是否已登录
            const alreadyLogged = ()=>{
                var isLogin =  store.getters.isLogin;
                state.loginState = isLogin ? false : true;
            }
            const goTo = (url, params) => {
                router.push({ path: url, query: params})
            }
            return {
                ...toRefs(state),
                login,
                skipUserCenter
            };
        }
    }
</script>